
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

import BlockForm from "../components/Form.vue";

@Component({
  components: {
    BlockForm
  }
})
export default class BlockCreatePage extends Vue {
  @Prop(String) readonly blockDate?: string;

  formData = {
    days: [],
    is_multi_task: 0,
    reiteration: 0
  };

  errors = null;

  created() {
    const date = !this.blockDate
      ? moment().format("YYYY-MM-DD")
      : this.blockDate;

    this.formData["start_date"] = date;
    this.formData["end_date"] = null;
  }

  block() {
    this.errors = null;

    // 🤷🏻‍♀️
    this.formData["date_from"] = this.formData["start_date"];

    this.$store
      .dispatch("schedule/block", this.formData)
      .then(({ data }) => {
        this.$router.push({
          name: "schedule",
          params: { errors: data.errors }
        });
      })
      .catch(error => {
        this.errors = error.response.data.errors;
      });
  }
}
